var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-page-title',{staticClass:"_border-bottom _mb-0",attrs:{"primary":_vm.title.primary,"secondary":_vm.title.secondary}}),(_vm.viewPage)?[_c('v-filters',_vm._b({on:{"update-url":_vm.updateUrl,"finish-filters-loading":_vm.finishFiltersLoading,"toggle-chart":function($event){_vm.showChart ^= true}}},'v-filters',{
          urlFilters: _vm.urlFilters,
          metricFilters: _vm.metricFilters,
          showChart: !!_vm.showChart,
          dateFilters: _vm.dateFilters,
          searchEngines: _vm.searchEngines,
          regions: _vm.regions,
          devices: _vm.devices
        },false)),(!_vm.preloader.region)?_c('div',{staticClass:"dashboard-wrapper _border-none _mt-32"},[(_vm.preloader.table && !_vm.preloader.filters)?_c('v-preloader',{attrs:{"static":"","clear":""}}):(!_vm.selectedItems.length && !_vm.preloader.filters)?_c('v-error',{attrs:{"title":"Сегменты не выбраны","subtitle":"Выберите сегменты для сравнения","show-links":false}}):(!!_vm.selectedItems.length && _vm.showChart)?_c('v-container',{staticClass:"dashboard-block _mb-32 _ha",attrs:{"has-data":!!_vm.selectedItems.length},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-select-tree-metric',_vm._b({on:{"update-url":_vm.updateUrl}},'v-select-tree-metric',{metrics: _vm.metrics, metricActive: _vm.urlFilters.metric},false))]},proxy:true}],null,false,872455512)},[_c('v-segment-metric-chart',{attrs:{"items":_vm.selectedItems,"metric":_vm.activeMetric,"date-filters":_vm.dateFilters,"date-filters-formatted":_vm.dateFiltersFormatted}})],1):_vm._e(),(!_vm.preloader.filters)?_c('v-overview-container',_vm._b({on:{"load-catalog":_vm.setItems}},'v-overview-container',{
            urlFilters: _vm.urlFilters,
            metricFilters: _vm.metricFilters,
            metricFiltersQuery: _vm.metricFiltersQuery,
            dateFilters: _vm.dateFilters,
            metricsVisible: _vm.metricsVisible,
            activeMetric: _vm.activeMetric,
            regionSelected: _vm.regionSelected,
          },false)):_vm._e()],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }