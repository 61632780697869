<template>
  <vue-item
      :colspan="colspan"
      @mouseenter.native="showButtons = true"
      @mouseleave.native="showButtons = false"
  >
    <v-table-head-cell
        v-bind="{
          center: true,
          sortColumn: true,
          showButtons: (showButtonsOnHover && showButtons) || !showButtonsOnHover,
          filter,
          orderBy,
          filterOptions,
          sort,
          toggleVisibility: true
        }"
    >
      <slot></slot>
    </v-table-head-cell>
  </vue-item>
</template>

<script>
import tableHeadCell from '../tableHeadCell'
export default {
  name: 'tableHeadCellContainer',

  components: {
    'v-table-head-cell': tableHeadCell
  },

  props: {
    colspan: {
      type: [Number, String]
    },

    showButtonsOnHover: {
      type: Boolean,
      default: false
    },

    filter: {
      type: Boolean,
      default: false
    },

    orderBy: {
      type: String
    },

    filterOptions: {
      type: Object,
      default: () => ({})
    },

    sort: {
      type: Boolean,
      default: false
    },

    toggleVisibility: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showButtons: false
    }
  }
}
</script>
