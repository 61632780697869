<template>
  <v-button
      class="filters-reset-button"
      name="Сбросить фильтры"
      type="text-base"
      icon="reset_filter_list"
      offset-icon="4px"
      padding-left="6px"
      padding-right="10px"
      height="32px"
      @click="resetFilters"
  />
</template>

<script>
import {isEqual} from 'underscore'

export default {
  name: 'filtersResetButton',

  props: {
    metrics: {
      type: Array,
      default: () => ([])
    }
  },

  methods: {
    resetFilters () {
      let newQuery = {...this.$route.query}

      this.metrics.forEach(metric => {
        if (newQuery[`${metric}_from`]) newQuery[`${metric}_from`] = undefined
        if (newQuery[`${metric}_to`]) newQuery[`${metric}_to`] = undefined
        if (newQuery[`${metric}_diff_from`]) newQuery[`${metric}_diff_from`] = undefined
        if (newQuery[`${metric}_diff_from`]) newQuery[`${metric}_from_to`] = undefined
        if (newQuery[`${metric}_category`]) newQuery[`${metric}_category`] = undefined
      })

      if (newQuery.order_by) newQuery.order_by = undefined
      if (newQuery.order_direction) newQuery.order_direction = undefined

      if (!isEqual(newQuery, this.$route.query)) {
        this.$router.push({query: newQuery})
      }
    }
  }
}
</script>

<style scoped lang="scss">
.filters-reset-button {
  & /deep/ .button__icon {
    width: 20px;
    height: 20px;
  }
}
</style>
