<template>
  <vue-line>
    <vue-item :style="style">
      <div class="query-name">
        <v-table-item-title
            :title="item.url_title"
            :url="item.url_name"
            :level="item.level"
            :is-listing="isListing"
            :listing-page="item.listingPage"
            :type="item.type_id"
            :entity-count="entityCount"
            class="_ml-0"
        />
      </div>
    </vue-item>
    <vue-item align="right">{{ level }}</vue-item>
    <template v-for="(metric, i) in metrics">
      <template v-if="comparison">
        <v-table-data-cell
            :key="`${i}-one`"
            :value="item[`${metric.id}_one`]"
            :is-listing="item.is_listing"
            :additional="metric.additional"
            :round-number-digits="metric.id === 'conversions' ? 2 : 0"
            :data-test="`table_cell_${metric.id}_one${isMainPage ? '--main' : ''}`"
            :class="[{_active: activeMetric === metric.id}]"
        />
        <v-table-data-cell
            :key="`${i}-two`"
            :value="item[`${metric.id}_two`]"
            :is-listing="item.is_listing"
            :additional="metric.additional"
            :round-number-digits="metric.id === 'conversions' ? 2 : 0"
            :data-test="`table_cell_${metric.id}_two${isMainPage ? '--main' : ''}`"
            :class="[{_active: activeMetric === metric.id}]"
        />
        <v-table-data-cell
            :key="`${i}-diff`"
            :value="item[`${metric.id}_diff`]"
            :is-listing="item.is_listing"
            :additional="metric.additional"
            :round-number-digits="metric.id === 'conversions' ? 2 : 0"
            diff
            :diff-prc="getDiffPrc(metric)"
            :data-test="`table_cell_${metric.id}_diff${isMainPage ? '--main' : ''}`"
            :class="[{_active: activeMetric === metric.id}]"
        />
      </template>

      <v-table-data-cell
          v-else
          :key="`${i}-value`"
          :value="item[metric.id]"
          :is-listing="item.is_listing"
          :additional="metric.additional"
          :round-number-digits="metric.id === 'conversions' ? 2 : 0"
          :data-test="`table_cell_${metric.id}${isMainPage ? '--main' : ''}`"
          :class="[{_active: activeMetric === metric.id}]"
      />
    </template>
  </vue-line>
</template>

<script>
import tableItemTitle from '@/services/components/tableItemTitle.vue'
import tableDataCell from '@/services/components/tableDataCell.vue'

import {roundNumber, hmsToSeconds} from '@/function'

export default {
  components: {
    'v-table-item-title': tableItemTitle,
    'v-table-data-cell': tableDataCell
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    comparison: {
      type: Boolean,
      default: false
    },

    metrics: {
      type: Array,
      default: () => ([])
    },

    activeMetric: {
      type: String,
      default: ''
    },

    clickableHead: {
      type: Boolean,
      default: true
    },

    level: {
      type: [Number, String],
      default: undefined
    }
  },

  computed: {
    style () {
      return { paddingLeft: `${8 + this.item.level * 30}px` }
    },

    active () {
      try {
        return this.item.active
      } catch (_) {
       return false
      }
    },

    isListing () {
      try {
        return this.item.is_listing
      } catch (_) {
        return false
      }
    },

    isMainPage () {
      try {
        return this.item.url_title.toLowerCase().includes('главная')
      } catch (_) {
        return false
      }
    },

    entityCount () {
      return this.$store.state.entityCountTypes.map(type => {
        return {
          type: type.id,
          count: this.item[`count_${type.id}`] || 0,
          name: type.name
        }
      })
    }
  },

  methods: {
    roundNumber,

    getDiffPrc (metric) {
      const diff = this.getValue(this.item[`${metric.id}_diff`])
      const one = this.getValue(this.item[`${metric.id}_one`])
      const two = this.getValue(this.item[`${metric.id}_two`])

      return metric.additional || diff === undefined
          ? undefined
          : diff === 0
              ? 0
              : diff === one
                  ? 100
                  : roundNumber(diff * 100 / two, 2)
    },

    getValue (val) {
      return typeof val === 'string' ? hmsToSeconds(val) : val
    }
  }
}
</script>

<style scoped lang="scss">
.table-line._active {
  .table-item {
    background: #F9FCFF !important;
  }
}

.query-name {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 450px;

  .button {
    &._active {
      & /deep/ .button__icon {
        fill: #0E32F5;
      }
    }
  }
}
</style>
