var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-item',{attrs:{"colspan":_vm.colspan},nativeOn:{"mouseenter":function($event){_vm.showButtons = true},"mouseleave":function($event){_vm.showButtons = false}}},[_c('v-table-head-cell',_vm._b({},'v-table-head-cell',{
        center: true,
        sortColumn: true,
        showButtons: (_vm.showButtonsOnHover && _vm.showButtons) || !_vm.showButtonsOnHover,
        filter: _vm.filter,
        orderBy: _vm.orderBy,
        filterOptions: _vm.filterOptions,
        sort: _vm.sort,
        toggleVisibility: true
      },false),[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }