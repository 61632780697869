<template>
  <div
      ref="container"
      class="dashboard-block _mb-32"
  >
    <v-container
        v-bind="{
          load: preloader,
          hasData: sortedMixedSegments.hasData
        }"
    >
      <template #title>
        <p class="total">Всего: <span>{{ sortedMixedSegments.total | formatCount }}</span></p>
      </template>

      <template #head-middle>
        <div class="buttons">
          <v-filters-reset-button :metrics="metricFilters" />
          <v-button
              class="settings-button"
              type="text-base"
              name="Настройки"
              icon="settings"
              offset-icon="4px"
              padding-left="6px"
              padding-right="10px"
              height="32px"
              @click="$modal.open({
                name: MODAL_TITLE.SETTINGS_TABLE_CELLS,
                props: {
                  tableCellsConfig: tableConfig,
                  saveTableCellsConfigFunc: saveTableCellsConfig,
                  page: pageConfig.page
                }
              })"
          />
        </div>
      </template>

      <v-overview-table
          :data="sortedMixedSegments"
          :date-filters="dateFilters"
          :table-cells-config="tableConfig"
          :metric-filters="metricFilters"
          :preloader-width="preloaderWidth"
          :metrics-visible="metricsVisible"
          :active-metric="urlFilters.metric"
      />

      <v-pagination
          :pageNumber="urlFilters.page"
          :limit="urlFilters.limit"
          :count="sortedMixedSegments.total"
          @limit="updateUrl({limit: $event})"
      />
    </v-container>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import filterWSByProject from '@/mixins/filterWSByProject.js'

import container from '@/services/components/container/'
import filtersResetButton from '@/services/components/filtersResetButton.vue'
import overviewTable from './overviewTable'

import {hmsToSeconds} from '@/function'
import updateUrl from '@/mixins/updateUrl'

import MODAL_TITLE from '@/modals/modalTitle'
import ROUTE_NAME from '@/router/routeName'

const TRAFFIC_FILTER_TYPES = ['source', 'medium']

export default {
  name: 'overviewContainer',

  mixins: [
    updateUrl,
    filterWSByProject
  ],

  provide () {
    return {
      tableCellsConfigKey: this.pageConfig.tableCellsConfigKey
    }
  },

  components: {
    'v-container': container,
    'v-filters-reset-button': filtersResetButton,
    'v-overview-table': overviewTable
  },

  props: {
    urlFilters: {
      type: Object,
      default: () => ({})
    },

    metricFilters: {
      type: Array,
      default: () => ([])
    },

    metricFiltersQuery: {
      type: Array,
      default: () => ([])
    },

    dateFilters: {
      type: Object,
      default: () => ({})
    },

    metricsVisible: {
      type: Array,
      default: () => ([])
    },

    activeMetric: {
      type: Object,
      default: () => ({})
    },

    regionSelected: {
      type: Array
    },
  },

  data () {
    return {
      preloader: true,
      MODAL_TITLE,
      preloaderWidth: 0,
    }
  },

  computed: {
    ...mapState({
      segmentTreeCatalog: state => state.segments.catalog.dataDF,
      segmentTreeCustom: state => state.segments.catalog.dataDFCustom
    }),

    ...mapGetters({
      mixedSegments: 'bi/overview/mixedSegments',
      cellsConfig: 'bi/overview/cellsConfig'
    }),

    pageConfig() {
      if (this.$route.name === ROUTE_NAME.BI_CATEGORY) {
        return {
          catalogUrl: '/catalog',
          tableCellsConfigKey:'catalogTableCellsConfig',
          tableCellsConfig: this.cellsConfig('catalogTableCellsConfig'),
          page: 'catalog',
          defaultMetric: 'ws2'
        }
      } else if (this.$route.name === ROUTE_NAME.BI_COMMERCE_OVERVIEW) {
        return {
          catalogUrl: '/ecomm',
          title: 'Commerce Overview',
          tableCellsConfigKey: 'commerceOverviewTableCellsConfig',
          tableCellsConfig: this.cellsConfig('commerceOverviewTableCellsConfig'),
          page: 'commerceOverview',
          defaultMetric: 'product_detail_views'
        }
      }
      return {}
    },

    tableConfig() {
      return this.filteredTableCellsConfig.length ? this.filteredTableCellsConfig : this.pageConfig.tableCellsConfig
    },

    sortedMixedSegments () {
      let items = [...this.mixedSegments.items]

      items.sort((a, b) => b[`${this.activeMetric.id}${this.dateFilters.comparison ? '_one' : ''}`] - a[`${this.activeMetric.id}${this.dateFilters.comparison ? '_one' : ''}`])

      return {
        hasData: this.mixedSegments.hasData,
        items,
        total: this.mixedSegments.total
      }
    },

    segmentsQueryParams () {
      let customSegmentsIds = this.findSegmentIds(this.segmentTreeCustom, this.urlFilters.catalog_ids)

      let catalog_ids = this.findSegmentIds(this.segmentTreeCatalog, this.urlFilters.catalog_ids)

      return {
        segments: customSegmentsIds.length ? customSegmentsIds : undefined,
        catalog_ids: catalog_ids.length ? catalog_ids : undefined
      }
    }
  },

  watch: {
    async $route (to, from) {
      const qt = to.query
      const qf = from.query

      if (qt.metric !== qf.metric) {
        return
      }
      this.setCancelRequests(true)
      await this.init()
    }
  },

  async created () {
    this.setCancelRequests(false)
    this.setTableCellsConfig()

    await this.init({
      order_direction: 'DESC',
      order_by: this.activeMetric.filterName
    })
  },

  destroyed () {
    this.setCancelRequests(true)
  },

  methods: {
    ...mapActions({
      getCatalog: 'bi/overview/getCatalog',
      getUserSegments: 'bi/overview/getUserSegments',
    }),

    ...mapMutations({
      saveTableCellsConfig: 'tableSettings/saveTableCellsConfig',
      toggleTableRows: 'catalog/toggleTableRows',
      setPreloader: 'catalog/setPreloader',
      setCancelRequests: 'setCancelRequests'
    }),

    setTableCellsConfig () {
      let tableCellsConfig = localStorage.getItem(this.pageConfig.tableCellsConfigKey)

      if (tableCellsConfig) {
        this.saveTableCellsConfig({newConfig: JSON.parse(tableCellsConfig), key: this.pageConfig.tableCellsConfigKey})
      } else {
        let newConfig = {}
        let oldConfig = this.tableConfig
        oldConfig.forEach(section => {
          newConfig[section.id] = section.items.some(metric => metric.visible)
          section.items.forEach(metric => {
            newConfig[metric.id] = metric.visible
          })
        })
        this.saveTableCellsConfig({newConfig, key: this.pageConfig.tableCellsConfigKey})
      }
    },

    /* Инициализация данных */
    async init (filters) {
      this.preloader = true
      this.$emit('load-catalog', this.preloader)
      try {
          this.requestData(1)
          await this.requestData(0, filters)
      } catch ({message}) {
        this.$notify({ type: 'error', title: message })
      } finally {
        this.preloader = false
        this.$emit('load-catalog', this.preloader)
      }
    },

    /* Загрузка данных */
    async requestData (summary, filters) {
      if (this.activeProjectId) {
        let query = {
          limit: this.urlFilters.limit,
          offset: (this.urlFilters.page - 1) * this.urlFilters.limit,
          project_id: this.activeProjectId,
          catalog_id: '0',
          skip_regionality: 1,
          v2: 1,
          order_direction: this.urlFilters.order_direction,
          order_by: this.urlFilters.order_by,
          catalog_filter_mode: 'as_is',
          is_mobile: this.urlFilters.is_mobile === 0 ? '0' : this.urlFilters.is_mobile,
          search_engine: this.urlFilters.search_engine,
          summary,
          traffic_source: this.urlFilters.traffic_source,
          ...filters
        }

        if (this.regionSelected) {
          query.region_id = this.regionSelected.map(region => region.yandex_region_id).filter(id => id)
          query.google_region_id = this.regionSelected.map(region => region.google_region_id)
          query.need_geo = this.regionSelected && this.regionSelected.length ? 1 : undefined
        }

        for (let type of TRAFFIC_FILTER_TYPES) {
          query[type] = this.urlFilters[type]
          query[`traffic_conditions[${type}]`] = this.urlFilters[`traffic_conditions[${type}]`]
        }

        this.metricFiltersQuery.forEach(filter => {
          if (this.urlFilters[filter]) {
            query[filter] = this.urlFilters[filter]
          }
        })

        if (this.dateFilters.comparison) {
          query.period_one_date_from = this.dateFilters.one.from
          query.period_one_date_to = this.dateFilters.one.to
          query.period_two_date_from = this.dateFilters.two.from
          query.period_two_date_to = this.dateFilters.two.to
        } else {
          query.date_from = this.dateFilters.one.from
          query.date_to = this.dateFilters.one.to
        }

        let requests = []
        const level = '0'

        if (summary) {
          requests.push(this.getCatalog({
            query: {
              ...query,
              summary
            },
            level,
            url: this.pageConfig.catalogUrl
          }))
        } else {
          if (!this.urlFilters.catalog_ids.length) {
            requests.push(this.getCatalog({
              query: {
                ...query,
              },
              level,
              url: this.pageConfig.catalogUrl
            }))
          }

          if (this.segmentsQueryParams.catalog_ids) {
            requests.push(this.getCatalog({
              query: {
                ...query,
                catalog_ids: this.segmentsQueryParams.catalog_ids
              },
              level,
              url: this.pageConfig.catalogUrl
            }))
          }

          if (this.segmentsQueryParams.segments) {
            delete query.catalog_id
            delete query.summary

            requests.push(this.getUserSegments({
              query: {
                ...query,
                segments: this.segmentsQueryParams.segments
              },
              level
            }))
          }
        }

        await Promise.all(requests)
      }
    },

    setPreloaderWidth () {
      try {
        this.preloaderWidth = this.$refs.container.getBoundingClientRect().width
      } catch (_) {
        this.preloaderWidth = '100%'
      }
    },

    findSegmentIds(segments, ids) {
      if (!segments || !segments.items || !ids || !ids.length) {
          return []
      }

      return ids.filter((id) => this.findIdInSegmentsTree(segments.items, id))
    },


    findIdInSegmentsTree (segments, id) {
      if (!segments || !segments.length || !id) {
        return false
      }

      return segments.find((segment) => {
        if (segment && segment.items && segment.items.length) {
          this.findIdInSegmentsTree(segment.items, id)
        }

        return segment.id === id
      })
    },
  }
}
</script>

<style scoped lang="scss">
.total {
  span {
    font-weight: 500;
  }
}

.settings-button {
  margin-left: 4px;

  & /deep/ .button__icon {
    width: 20px;
    height: 20px;
  }
}

.buttons {
  display: flex;
  align-items: center;
}
</style>
