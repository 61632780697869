<template>
  <div>
    <v-page-title
        class="_border-bottom _mb-0"
        :primary="title.primary"
        :secondary="title.secondary"
    />

    <template v-if="viewPage">
      <v-filters
          v-bind="{
            urlFilters,
            metricFilters,
            showChart: !!showChart,
            dateFilters,
            searchEngines,
            regions,
            devices
          }"
          @update-url="updateUrl"
          @finish-filters-loading="finishFiltersLoading"
          @toggle-chart="showChart ^= true"
      />

      <div
          v-if="!preloader.region"
          class="dashboard-wrapper _border-none _mt-32"
      >
        <v-preloader
            v-if="preloader.table && !preloader.filters"
            static
            clear
        />
        <v-error
            v-else-if="!selectedItems.length && !preloader.filters"
            title="Сегменты не выбраны"
            subtitle="Выберите сегменты для сравнения"
            :show-links="false"
        />
        <v-container
            v-else-if="!!selectedItems.length && showChart"
            class="dashboard-block _mb-32 _ha"
            :has-data="!!selectedItems.length"
        >
          <template #title>
            <v-select-tree-metric
                v-bind="{metrics, metricActive: urlFilters.metric}"
                @update-url="updateUrl"
            />
          </template>

          <v-segment-metric-chart
              :items="selectedItems"
              :metric="activeMetric"
              :date-filters="dateFilters"
              :date-filters-formatted="dateFiltersFormatted"
          />
        </v-container>

        <v-overview-container
            v-if="!preloader.filters"
            v-bind="{
              urlFilters,
              metricFilters,
              metricFiltersQuery,
              dateFilters,
              metricsVisible,
              activeMetric,
              regionSelected,
            }"
            @load-catalog="setItems"
        />
      </div>
    </template>
  </div>
</template>

<script>
import {mapState, mapMutations, mapGetters, mapActions} from 'vuex'
import {findOptions} from '@/function'

import filterWSByProject from '@/mixins/filterWSByProject.js'
import updateUrl from '@/mixins/updateUrl'
import regionFilter from '@/mixins/regionFilter'

import filters from './filters'
import overviewContainer from './components/overview'
import segmentMetricChart from '../../components/segmentMetricChart'
import error from '@/services/errors/block'
import container from '@/services/components/container/'
import selectTreeMetric from '@/services/components/selectTreeMetric'
import ROUTE_NAME from '@/router/routeName'

import CONSTANTS from '@/const/'

const PERIOD_COUNT = ['one', 'two']
const TRAFFIC_FILTER_TYPES = ['source', 'medium']

export default {
  name: 'overview',

  metaInfo () {
    return {
      title: `${this.title.primary} / ${this.title.secondary} / ${this.activeProjectData.label} (${this.activeProjectData.id})`
    }
  },

  mixins: [
    updateUrl,
    filterWSByProject,
    regionFilter
  ],

  components: {
    'v-filters': filters,
    'v-overview-container': overviewContainer,
    'v-segment-metric-chart': segmentMetricChart,
    'v-error': error,
    'v-container': container,
    'v-select-tree-metric': selectTreeMetric
  },

  data () {
    return {
      availableColors: [],

      preloader: {
        table: true,
        filters: true,
        region: true
      },

      selectedItems: [],

      showChart: true,

      searchEngines: [
        {id: CONSTANTS.SEARCH_SYSTEM.GOOGLE, name: 'Google'},
        {id: CONSTANTS.SEARCH_SYSTEM.YANDEX, name: 'Яндекс'}
      ]
    }
  },

  computed: {
    ...mapState({
      activeProjectData: state => state.active_project_data,
      segmentTreeCatalog: state => state.segments.catalog.dataDF,
      segmentTreeCustom: state => state.segments.catalog.dataDFCustom,
      trafficConditions: state => state.trafficConditions,
      filtersTraffic: state => state.filtersTraffic,
      trafficSources: state => state.trafficSources,
      catalog: state => state.bi.overview.catalog,
      userSegments: state => state.bi.overview.userSegments,
    }),

    ...mapGetters({
      filtersDevices: 'filtersDevices',
      mixedSegments: 'bi/overview/mixedSegments',
      cellsConfig: 'bi/overview/cellsConfig'
    }),

    pageConfig() {
      if (this.$route.name === ROUTE_NAME.BI_CATEGORY) {
        return {
          title: 'Overview',
          tableCellsConfig: this.cellsConfig('catalogTableCellsConfig'),
          defaultMetric: 'count_all'
        }
      } else if (this.$route.name === ROUTE_NAME.BI_COMMERCE_OVERVIEW) {
        return {
          title: 'Commerce Overview',
          tableCellsConfig: this.cellsConfig('commerceOverviewTableCellsConfig'),
          defaultMetric: 'product_detail_views'
        }
      }
      return {}
    },

    title() {
        return {
          primary: 'BI',
          secondary: this.pageConfig.title
      }
    },

    allTreeSegments () {
      let allSegments = JSON.parse((JSON.stringify(this.segmentTreeCustom)))
      allSegments.items = allSegments.items.concat(this.segmentTreeCatalog.items)
      return allSegments
    },

    metricFilters () {
      let items = [
        'ws2',
        'top3',
        'top5',
        'top10',
        'p_traf',
        'sessions',
        'users',
        'new_users',
        'transactions',
        'conversions',
        'transaction_revenue',
      ]
      if (!this.shouldFilterWSMetrics)  {
        items.push('ws1')
        items.push('ws3')
      }
      return items
    },

    viewPage () {
      return this.activeProjectData && Object.keys(this.activeProjectData).length !== 0
    },

    /* Фильтры в URL */
    urlFilters () {
      let queryParams = {...this.$route.query}
      let filters = {
        date: {
          one: {
            from: undefined,
            to: undefined
          },
          two: {
            from: undefined,
            to: undefined
          }
        }
      }

      /* Инициализация URL фильтров */
      const initFilters = (query, options, required = false, defaultOption, cycle, optionKey = 'id', number = false, string = false) => {
        try {
          if (cycle) {
            if (query) {
              /* Проверка наличия опций в фильтрах */
              if (query && Array.isArray(query)) {
                return query.map(f => number ? +f : f).filter(f => f !== null && f !== undefined && ((!string && !isNaN(f)) || string))
              } else if (typeof query === 'string' && number && !isNaN(+query)) {
                return [+query]
              } else if ((typeof query === 'number' && !isNaN(+query)) || typeof query === 'string' && !number) {
                return [query]
              } else {
                return defaultOption || []
              }
            } else {
              return defaultOption || []
            }
          } else {
            if (typeof query === 'object' && options) {
              for (let item of options) {
                if (query.source && query.source === item.source && query.medium && query.medium === item.medium) {
                  return item.id
                }
              }
            } else {
              if ((query || query === 0) && findOptions(query, options, optionKey)) {
                return query
              } else if (required) {
                return defaultOption || options[0].id
              } else {
                return null
              }
            }
          }
        } catch (_) {
          return null
        }
      }

      filters.limit = queryParams.limit && +queryParams.limit <= 100 ? +queryParams.limit : 50

      filters.page = +queryParams.page ? +queryParams.page : 1

      filters.catalog_ids = initFilters(queryParams.catalog_ids, this.allTreeSegments.items, false, this.catalog.items.slice(0, 5).map(item => String(item.catalog_id)), true).filter(id => !isNaN(+id))

      this.metricFiltersQuery.forEach(filter => {
        filters[filter] = queryParams[filter]
      })

      filters.order_by = queryParams.order_by

      filters.order_direction = queryParams.order_direction

      filters.metric = queryParams.metric || this.pageConfig.defaultMetric

      filters.is_mobile = initFilters(+queryParams.is_mobile, this.devices)

      filters.search_engine = initFilters(queryParams.search_engine, this.searchEngines)

      filters.region_id = initFilters(queryParams.region_id, this.regions, false, undefined, true, 'google_region_id', true)

      filters.traffic_source = initFilters(queryParams.traffic_source, this.trafficSources, true, 'ga4')

      for (let type of TRAFFIC_FILTER_TYPES) {
        filters[type] = initFilters(queryParams[type], this.filtersTraffic[type].items, false, [], true, undefined, false, true)
        filters[`traffic_conditions[${type}]`] = initFilters(queryParams[`traffic_conditions[${type}]`], this.trafficConditions)
      }

      for (let count of PERIOD_COUNT) {
        if (queryParams[`period_${count}_date_from`] && queryParams[`period_${count}_date_to`]) {
          let dateFrom = +this.$moment.utc(queryParams[`period_${count}_date_from`], CONSTANTS.DATE_FORMAT.BASE_FORMAT).format('x')
          let dateTo = +this.$moment.utc(queryParams[`period_${count}_date_to`], CONSTANTS.DATE_FORMAT.BASE_FORMAT).format('x')

          filters.date[count] = {
            from: dateFrom || dateTo,
            to: dateTo || dateFrom
          }
        } else if (count === 'one') {
          if (queryParams[`period_${count}_date_to`]) {
            let dateTo = +this.$moment.utc(queryParams[`period_${count}_date_to`], CONSTANTS.DATE_FORMAT.BASE_FORMAT).format('x')

            filters.date[count] = {
              from: +this.$moment.utc(dateTo, 'x').subtract(7, 'days').format('x'),
              to: dateTo
            }
          } else if (queryParams[`period_${count}_date_from`]) {
            let dateFrom = +this.$moment.utc(queryParams[`period_${count}_date_from`], CONSTANTS.DATE_FORMAT.BASE_FORMAT).format('x')
            let dateTo = +this.$moment.utc(dateFrom, 'x').add(7, 'days').format('x')
            filters.date[count].from = dateFrom

            if (dateTo > +this.$moment.utc().endOf('day').format('x')) {
              filters.date[count].to = +this.$moment.utc().startOf('day').format('x')
            } else {
              filters.date[count].to = dateTo
            }
          } else {
            let dateDefault = +this.$moment.utc().subtract(1, 'days').startOf('day').format('x')
            filters.date[count] = {
              from: +this.$moment.utc(dateDefault, 'x').subtract(7, 'days').format('x'),
              to: dateDefault
            }
          }
        }
      }

      return filters
    },

    metricFiltersQuery () {
      let filters = []

      this.metricFilters.forEach(filter => {
        filters.push(`${filter}_from`)
        filters.push(`${filter}_to`)
        filters.push(`${filter}_diff_from`)
        filters.push(`${filter}_diff_to`)
      })

      return filters
    },

    dateFilters () {
      let dateFilters = {
        one: {
          from: undefined,
          to: undefined
        },
        two: {
          from: undefined,
          to: undefined
        }
      }

      const urlFiltersDate = {...this.urlFilters.date}

      for (let count of PERIOD_COUNT) {
        try {
          if (urlFiltersDate[count] && urlFiltersDate[count].from) {
            dateFilters[count].from = this.$moment(urlFiltersDate[count].from).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT)
          }

          if (urlFiltersDate[count] && urlFiltersDate[count].to) {
            dateFilters[count].to = this.$moment(urlFiltersDate[count].to).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT)
          }
        } catch (_) {
        }
      }

      return {
        ...dateFilters,
        comparison: !!dateFilters.two.from && !!dateFilters.two.to && (dateFilters.one.from !== dateFilters.two.from || dateFilters.one.to !== dateFilters.two.to)
      }
    },

    /* Список всех метрик */
    metrics () {
      let metrics = []
      let config = this.filteredTableCellsConfig.length ? this.filteredTableCellsConfig : this.pageConfig.tableCellsConfig

      config.forEach(section => {
        metrics.push({...section, parent_id: '0'})
        section.items.forEach(metric => {
          metrics.push({...metric, parent_id: section.id})
        })
      })

      return metrics
    },

    /* Список метрик для отображения */
    metricsVisible () {
      return this.metrics.filter(metric => metric.visible && metric.parent_id !== '0')
    },

    activeMetric () {
      try {
        let metric

        metric = this.metrics.find(item => item.id === this.urlFilters.metric)
        return metric
      } catch (_) {
        return undefined
      }
    },

    dateFiltersFormatted() {
      const {one, two} = this.dateFilters

      return {
        one: {
          from: this.$moment(one.from, CONSTANTS.DATE_FORMAT.BASE_FORMAT).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT_REVERSE_DOT),
          to: this.$moment(one.to, CONSTANTS.DATE_FORMAT.BASE_FORMAT).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT_REVERSE_DOT)
        },
        two: {
          from: this.$moment(two.from, CONSTANTS.DATE_FORMAT.BASE_FORMAT).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT_REVERSE_DOT),
          to: this.$moment(two.to, CONSTANTS.DATE_FORMAT.BASE_FORMAT).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT_REVERSE_DOT)
        }
      }
    },

    devices() {
      return this.filtersDevices
    },

    regionSelected() {
      return this.getRegionSelected(this.urlFilters.region_id)
    }
  },

  async created () {
    this.clearData()

    try {
      await this.getFilters({
        query: {
          project_id: this.activeProjectId,
          skip_regionality: 1
        }
      })
    } catch ({message}) {
      this.$notify({title: message, type: CONSTANTS.NOTIFY_STATUS.ERROR})
    }

    await this.getRegions()

    this.preloader.region = false
  },

  methods: {
    ...mapActions(['getFilters']),

    ...mapMutations({
      clearData: 'bi/overview/clearData'
    }),

    setItems (value) {
      this.$set(this.preloader, 'table', value)

      if (!value) {
        this.selectedItems = []
        let item

        this.urlFilters.catalog_ids.forEach(id => {
          for (let segmentItem of this.mixedSegments.items) {
            if (id == segmentItem.catalog_id) {
              item = segmentItem
              break
            }
          }

          if (!item) {
            const treeItem = findOptions(id, this.allTreeSegments.items)

            if (treeItem) {
              item = findOptions(+treeItem.parent_id, this.mixedSegments.items, 'catalog_id')
            }
          }

          if (item && !findOptions(item.catalog_id, this.selectedItems, 'catalog_id')) {
            this.selectedItems.push(item)
          }
        })
      }
    },

    finishFiltersLoading () {
      this.$set(this.preloader, 'filters', false)
    }
  }
}
</script>
