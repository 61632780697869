<template>
  <div>
    <v-preloader
        v-show="preloader"
        static
    />
    <div
        v-show="!preloader"
        class="dashboard-filters"
    >
      <div class="dashboard-filters__line">
        <div class="dashboard-filters__item">
          <s-select
              id="filters_search_engine"
              v-model="filters.search_engine"
              v-bind="{
                options: searchEngines,
                insideLabel: 'ПС',
                placeholder: 'Выберите ПС',
                clearButton: true
              }"
              @input="updateFilters('search_engine', $event)"
          />
        </div>

        <div class="dashboard-filters__item">
          <v-filter-region
              :regions="regions"
              :value="filters.region_id"
              @input="updateFilters('region_id', $event)"
          />
        </div>

        <div class="dashboard-filters__item">
          <s-select
              id="filters_device"
              v-bind="{
                options: devices,
                insideLabel: 'Устройство',
                placeholder: 'Выберите устройство',
                minWidth: '270px',
                clearButton: true,
                appendToBody: false
              }"
              v-model="filters.is_mobile"
              @input="updateFilters('is_mobile', $event)"
          />
        </div>

        <div class="dashboard-filters__item _right">
          <s-daterangepicker-comparison
              id="filters_date"
              v-bind="{
                placeholder: 'Выберите дату',
                dynamicWidthDrop: true,
                dropPosition: 'bottom-end',
                availablesTo: availableDateTo
              }"
              v-model="filters.date"
              @input="updateFilters('date', $event)"
          />
        </div>
      </div>
      <div class="dashboard-filters__line">
        <div class="dashboard-filters__item">
          <v-traffic-source-toggle :value="urlFilters.traffic_source" />
        </div>

        <div class="dashboard-filters__item">
          <v-filter-traffic
              title="Источник"
              filter-name="source"
              :date-filters="dateFilters.one"
              :url-filters="urlFilters"
          />
        </div>

        <div class="dashboard-filters__item">
          <v-filter-traffic
              title="Канал"
              filter-name="medium"
              :date-filters="dateFilters.one"
              :url-filters="urlFilters"
          />
        </div>

        <div class="dashboard-filters__item _right">
          <v-button
              :name="showChart ? 'Скрыть график' : 'Показать график'"
              type="base"
              @click="$emit('toggle-chart')"
          />
        </div>
      </div>

      <div class="dashboard-filters__line">
        <div class="dashboard-filters__item">
          <v-select-tree-mixed-segments
              v-bind="{
                ref: 'selectSegments',
                filters,
                insideLabel: 'Сегменты',
                placeholder: 'Выберите сегмент'
              }"
              @update-url="updateUrl"
              @loaded="finishLoading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CONSTANTS from '@/const/'

import trafficSourceMixin from '@/mixins/trafficSourceMixin'

import selectTreeMixedSegments from '@/services/components/selectTreeMixedSegments/'
import filterTraffic from '@/services/components/filterTraffic'
import filterRegion from '@/services/components/filterRegion'

export default {
  mixins: [trafficSourceMixin],

  components: {
    'v-select-tree-mixed-segments': selectTreeMixedSegments,
    'v-filter-traffic': filterTraffic,
    'v-filter-region': filterRegion
  },

  props: {
    activeProjectId: {
      type: [String, Number]
    },

    updates: {
      type: Object,
      default: function () {
        return {
          has_data: false,
          items: []
        }
      }
    },

    urlFilters: {
      type: Object,
      default: function () {
        return {}
      }
    },

    metricFilters: {
      type: Array,
      default: () => ([])
    },

    showChart: {
      type: Boolean,
      default: true
    },

    dateFilters: {
      type: Object,
      default: () => ({})
    },

    searchEngines: {
      type: Array,
      default: () => ([])
    },

    regions: {
      type: Array,
      default: () => ([])
    },

    devices: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      filters: {},

      availableDateTo: +this.$moment.utc().startOf('day').subtract(1, 'days').format('x'),

      preloader: true
    }
  },

  watch: {
    urlFilters () {
      this.setFilters()
    },

    preloader(to) {
      if (!to) {
        this.$nextTick(() => {
          const select = this.$refs.selectSegments && this.$refs.selectSegments.$refs.selectSegments

          if (select) {
            select.setLabelWidth()
          }
        })
      }
    }
  },

  created () {
    this.setFilters()
  },

  methods: {
    /* Изменение URL фильтров */
    updateFilters(filter, value) {
      switch (filter) {
        case 'date':
          const queryRoute = this.$route.query
          let query = {
            period_one_date_from: this.$moment.utc(+value.one.from).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT),
            period_one_date_to: this.$moment.utc(+value.one.to).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT),
            period_two_date_from: undefined,
            period_two_date_to: undefined
          }

          if (value.two.from && value.two.to) {
            query.period_two_date_from = this.$moment.utc(+value.two.from).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT)
            query.period_two_date_to = this.$moment.utc(+value.two.to).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT)
          }

          for (let metric of this.metricFilters) {
            query[`${metric}_from`] = undefined
            query[`${metric}_to`] = undefined
            query[`${metric}_diff_from`] = undefined
            query[`${metric}_diff_to`] = undefined

            if ((queryRoute.period_two_date_from && queryRoute.period_two_date_to && !value.two.from && !value.two.to) || (!queryRoute.period_two_date_from && !queryRoute.period_two_date_to && value.two.from && value.two.to)) {
              query.order_by = undefined
              query.order_direction = undefined
            }
          }

          this.updateUrl(query)
          break

        case 'is_mobile':
          if (value === 0) {
            this.updateUrl({[filter]: String(value)})
          } else {
            this.updateUrl({[filter]: value})
          }
          break

        default:
          this.updateUrl({[filter]: value})
      }
    },

    /* Обновление URL фильтров */
    updateUrl(query) {
      this.$emit('update-url', query)
    },

    setFilters() {
      this.$set(this, 'filters', {
        ...this.urlFilters
      })
    },

    finishLoading () {
      this.preloader = false
      this.$emit('finish-filters-loading')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
