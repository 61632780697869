<template>
  <vue-table
      small-padding
      head-background
      fixed-bar
      fixed-head
      :offset-top="56"
      :hoverable-rows="false"
  >
    <template #head>
      <vue-line>
        <vue-item
            :rowspan="dateFilters.comparison ? 3 : 2"
            valign="bottom"
        >
          Сегменты
        </vue-item>
        <vue-item
            :rowspan="dateFilters.comparison ? 3 : 2"
            valign="bottom"
        >
          Уровень вложенности
        </vue-item>
        <template v-for="cell of tableCellsConfig">
          <vue-item
              v-if="cell.items.filter(item => item.visible).length"
              :key="cell.id"
              :colspan="getColspan(cell)"
          >
            {{ cell.name }}
          </vue-item>
        </template>
      </vue-line>
      <vue-line>
        <v-table-head-cell-container
            v-for="(metric, i) of metricsVisible"
            :key="`${metric.id}-${i}`"
            :colspan="dateFilters.comparison ? 3 : 1"
            :class="[{_active: clickableHead && activeMetric === metric.id}]"
            :filter="metricFilters.includes(metric.filterName)"
            :order-by="metric.filterName ? metric.filterName : ''"
            :filter-options="{
                metric: metric,
                diff: dateFilters.comparison
              }"
            :sort="!dateFilters.comparison && metricFilters.includes(metric.filterName)"
            show-buttons-on-hover
        >
            <span
                :class="['table-head-metric-name', {_clickable: clickableHead}]"
                @click="selectMetric(metric.id)"
            >{{ metric.name }}</span>
        </v-table-head-cell-container>
      </vue-line>

      <vue-line v-if="dateFilters.comparison">
        <template v-for="(metric, i) of metricsVisible">
          <vue-item
              :key="`one-${i}`"
              :class="['table-segment-metric', '_nowrap', {_active: clickableHead && activeMetric === metric.id}]"
          >
            <v-table-head-cell
                :order-by="metric.filterName ? `${metric.filterName}_one` : ''"
                center
                :sort="metricFilters.includes(metric.filterName)"
                sort-column
            >
              {{ dateFiltersFormatted.one.from}} -<br>
              {{ dateFiltersFormatted.one.to }}
            </v-table-head-cell>
          </vue-item>
          <vue-item
              :key="`two-${i}`"
              :class="['table-segment-metric', '_nowrap', {_active: clickableHead && activeMetric === metric.id}]"
          >
            <v-table-head-cell
                :order-by="metric.filterName ? `${metric.filterName}_two` : ''"
                center
                :sort="metricFilters.includes(metric.filterName)"
                sort-column
            >
              {{ dateFiltersFormatted.two.from}} -<br>
              {{ dateFiltersFormatted.two.to }}
            </v-table-head-cell>
          </vue-item>
          <vue-item
              :key="`diff-${i}`"
              :class="['table-segment-metric', {_active: clickableHead && activeMetric === metric.id}]"
          >
            <v-table-head-cell
                :order-by="metric.filterName ? `${metric.filterName}_diff` : ''"
                center
                :sort="metricFilters.includes(metric.filterName)"
                sort-column
            >
              Дельта
            </v-table-head-cell>
          </vue-item>
        </template>
      </vue-line>
    </template>
    <template #body>
      <template v-if="data.items && data.items.length">
        <v-table-row
            :key="`${JSON.stringify(summaryItem)}`"
            :item="summaryItem"
            :comparison="dateFilters.comparison"
            :metrics="metricsVisible"
            class="summary"
            :active-metric="activeMetric"
        />
        <template v-for="item of data.items">
          <v-table-row
              :key="`${JSON.stringify(item)}`"
              :item="item"
              :comparison="dateFilters.comparison"
              :metrics="metricsVisible"
              :active-metric="activeMetric"
              :clickable-head="clickableHead"
              :level="getLevel(item.catalog_id)"
          />
        </template>
      </template>
      <vue-line v-else>
        <vue-item :colspan="tableCellsCount">
          <v-no-data
              :style="{
              width: typeof preloaderWidth === 'string' ? preloaderWidth : `${preloaderWidth}px`,
              left: `${preloaderLeft}px`
            }"
          />
        </vue-item>
      </vue-line>
    </template>
  </vue-table>
</template>

<script>
import {mapState} from 'vuex'
import {findOptions} from '@/function'
import { DATE_FORMAT } from '@/const'

import tableRow from './overviewTableRow'
import noData from '@/services/errors/noData.vue'
import tableHeadCell from '@/components/tableHeadCell/tableHeadCell.vue'
import tableHeadCellContainer from '@/components/tableHeadCell/container/tableHeadCellContainer'

import updateUrl from '@/mixins/updateUrl'

export default {
  mixins: [updateUrl],

  components: {
    'v-table-row': tableRow,
    'v-no-data': noData,
    'v-table-head-cell': tableHeadCell,
    'v-table-head-cell-container': tableHeadCellContainer
  },

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    dateFilters: {
      type: Object,
      default: () => ({})
    },

    tableCellsConfig: {
      type: Array,
      default: () => ([])
    },

    metricFilters: {
      type: Array,
      default: () => ([])
    },

    preloaderWidth: {
      type: [Number, String],
      default: 0
    },

    clickableHead: {
      type: Boolean,
      default: true
    },

    metricsVisible: {
      type: Array,
      default: () => ([])
    },

    activeMetric: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      preloaderLeft: 0
    }
  },

  computed: {
    ...mapState({
      preloaderTable: state => state.catalog.preloaderTable,
      summary: state => state.bi.overview.summary,
      segmentsCatalogDF: state => state.segments.catalog.dataDF
    }),

    dateFiltersFormatted() {
      const {one, two} = this.dateFilters

      return {
        one: {
          from: this.$moment(one.from, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE),
          to: this.$moment(one.to, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE)
        },
        two: {
          from: this.$moment(two.from, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE),
          to: this.$moment(two.to, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE)
        }
      }
    },

    tableCellsCount () {
      let count = this.metricsVisible.length + 1

      if (this.dateFilters.comparison) count *= 3

      return count + 1
    },

    summaryItem () {
      this.tableCellsConfig
      return {
        ...this.summary.data,
        url_title: 'Итого'
      }
    }
  },

  mounted () {
    try {
      document.querySelector('.__panel').addEventListener('scroll', this.setPreloaderLeft)
    } catch (_) {
    }
  },

  methods: {
    getColspan (cell) {
      const count = cell.items.reduce((prev, curr) => {
        if (curr.visible) {
          prev++
        }
        return prev
      }, 0)

      return this.dateFilters.comparison ? count * 3 : count
    },

    setPreloaderLeft () {
      try {
        this.preloaderLeft = document.querySelector('.__panel').scrollLeft
      } catch (_) {
      }
    },

    selectMetric (metric) {
      if (this.clickableHead && this.metric !== metric) {
        this.updateUrl({metric})
      }
    },

    getLevel (id) {
      const item = findOptions(String(id), this.segmentsCatalogDF.items)

      return item && String(item.level) || '-'
    }
  }
}
</script>

<style scoped lang="scss">
.table {
  &-head-metric-name {
    white-space: nowrap;

    &._clickable {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-line._active {
    .table-item {
      background: #F9FCFF !important;
    }
  }

  &-item {
    &._nowrap {
      white-space: nowrap;
    }

    &._active {
      color: #0E32F5;
    }
  }

  &-fixed {
    & /deep/ .table {
      table-layout: fixed !important;
    }
  }
}

.summary {
  font-weight: 500;

  & /deep/ {
    .table-item {
      padding: 12px 8px !important;
    }

    .name-container {
      margin-left: 0;
    }
  }
}
</style>
