var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticClass:"dashboard-block _mb-32"},[_c('v-container',_vm._b({scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{staticClass:"total"},[_vm._v("Всего: "),_c('span',[_vm._v(_vm._s(_vm._f("formatCount")(_vm.sortedMixedSegments.total)))])])]},proxy:true},{key:"head-middle",fn:function(){return [_c('div',{staticClass:"buttons"},[_c('v-filters-reset-button',{attrs:{"metrics":_vm.metricFilters}}),_c('v-button',{staticClass:"settings-button",attrs:{"type":"text-base","name":"Настройки","icon":"settings","offset-icon":"4px","padding-left":"6px","padding-right":"10px","height":"32px"},on:{"click":function($event){return _vm.$modal.open({
              name: _vm.MODAL_TITLE.SETTINGS_TABLE_CELLS,
              props: {
                tableCellsConfig: _vm.tableConfig,
                saveTableCellsConfigFunc: _vm.saveTableCellsConfig,
                page: _vm.pageConfig.page
              }
            })}}})],1)]},proxy:true}])},'v-container',{
        load: _vm.preloader,
        hasData: _vm.sortedMixedSegments.hasData
      },false),[_c('v-overview-table',{attrs:{"data":_vm.sortedMixedSegments,"date-filters":_vm.dateFilters,"table-cells-config":_vm.tableConfig,"metric-filters":_vm.metricFilters,"preloader-width":_vm.preloaderWidth,"metrics-visible":_vm.metricsVisible,"active-metric":_vm.urlFilters.metric}}),_c('v-pagination',{attrs:{"pageNumber":_vm.urlFilters.page,"limit":_vm.urlFilters.limit,"count":_vm.sortedMixedSegments.total},on:{"limit":function($event){return _vm.updateUrl({limit: $event})}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }